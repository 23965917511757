import React, { useContext } from 'react'
import { createPortal } from 'react-dom'
import { ImageContext } from '../providers/ImageProvider'

const Modal = () => {
    const {show, setShow} = useContext(ImageContext)
  return show && createPortal(
    <>
        <div className='w-full h-[100vh] flex items-center justify-center fixed top-0' >

            <div className='w-full h-[100vh] opacity-30 absolute bg-black' onClick={()=>setShow(false)}>

            </div>

            <div className='w-1/2 h-1/2 absolute flex justify-center items-center z-50'>
                <img src={`https://media.licdn.com/dms/image/D4D03AQFitJTL4iRqnA/profile-displayphoto-shrink_400_400/0/1695746288178?e=1707350400&v=beta&t=ZXDb1VpjjjrTPpVoZZ4u7Bsi0XSKiN3-fLfRNJo1lyQ`} alt="" className='rounded-full'/>
            </div>





        </div>
    </>,
    document.getElementById("modal")
  )
}

export default Modal
