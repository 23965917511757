import React, { createContext, useState } from 'react'

export const ImageContext = createContext();

const ImageProvider = ({children}) => {

    const [show, setShow] = useState(false)
  return (
    <ImageContext.Provider value={{show, setShow}}>
        {children}

    </ImageContext.Provider>
  )
}

export default ImageProvider
