
import './sass/dist/css/main.css'
import Render from "./components/Render";
import Cursor from './components/Cursor';
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import Modal from "./components/Modal";

function App() {



  useEffect(() => {
    toast("Make sure to try the Speech Navigation Feature")
    toast("Simply tap anywhere on the screen and mention any of the Navigation options/links")
    toast("Example: Say 🗣 RESUME or 🗣CONTACT ")
    toast("Example: Say 🗣 LINKEDIN or 🗣GITHUB ")
    toast("Example: Say 🗣 PROJECTS or 🗣CONTACT, you get the gist right? right. ")
  }, [])

  return (
    <div className="h-[100vh] select-none">
       <ToastContainer
                position="top-right"
                autoClose={20000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

      <Modal />

      <Render />
      <Cursor />
    </div>
  );
}

export default App;
