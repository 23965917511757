import React from 'react'
import { FaEye, FaCode } from 'react-icons/fa'
import { Reveal} from "react-awesome-reveal";

const data = [
    {
        name: "QA Engineer (Lendsqr)",
        technologies:"Java + Selenium",
        description: "Resolved 12 critical issues, including modification of error messages, UI enhancements, and functionality improvements across various applications.Successfully managed and executed 7 critical projects, ensuring their timely completion.Developed and implemented 6 comprehensive test flows for the Irorun mobile app, covering essential functionalities like login, loan application, cancellation, top-up, transfer, and logout.Proactively identified and resolved issues in the web app, enhancing user experience and product reliability.",
        github: "https://github.com/spyatmycode/",
        live:"https://github.com/spyatmycode/"
    },
    {
        name: "Transcript System",
        technologies:"React + TailwindCSS + Firebase",
        description: "I built a transcript system using React that streamlines the process of generating result transcripts for students by the admin. This system allows for easy searching and reviewing of academic records and can generate accurate and detailed transcripts with just a few clicks.",
        github: "https://github.com/spyatmycode/transcript-system-1",
        live:"https://github.com/spyatmycode/transcript-system-1"
    },
    {
        name: "Ticketing System",
        technologies:"MongoDB + ExpressJS + ReactJS + NodeJS",
        description:"Watch how we transform the way tickets for events are managed and held in Bells University of Technology. In the early stages of development (Requirement gathering). Currently cooking.",
        github: "https://github.com/spyatmycode",
        live:"https://github.com/spyatmycode/"
    },
]

const Project =({name,tech,live,code, description})=>{
    return(
        <>
            <div className='px-11 my-5 gap-3 text-base capitalize font-[400] tracking-wider flex flex-col py-5 lg:w-[500px] bg-slate-500 bg-opacity-5 rounded-md single_project'>
                <span className='text-xl'>
                    <h3>
                        {name}
                        {/* Weather App */}
                    </h3>
                </span>

                <span className='font-bold text-blue-400 uppercase leading-5 tracking-wide'>
                    {tech}
                    {/* ReactJS + TailWindCSS  */}
                </span>

                <span>
                    <p>
                        {description}
                        {/* Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sunt blanditiis ea magnam quas numquam atque officiis minima laborum similique et? */}
                    </p>
                </span>

                <span className='flex gap-5'>
                    <a href={live} target='_blank' className='flex items-center gap-2'> 
                        <FaEye />
                        <span>Live</span>
                    </a>
                    <a href={code} className='flex items-center gap-2'>
                        <FaCode />
                        Code


                    </a>
                </span>
            </div>
        </>
    )
}

const Projects = () => {
  return (
    <>
       <div className='font-bold text-left text-xl uppercase opacity-80' id='projects'>Projects</div>

        <div className='flex flex-col gap-7 items-center justify-center ' >
        
            {
                data.map((project, index)=>{
                    const {name,live, github,technologies,description} = project

                    return (
                       <Reveal triggerOnce={true} key={index}>
                         <Project key={index} name={name} code={github} tech={technologies} live={live} description={description}/>
                       </Reveal>
                    )
                })
            }
        </div>
    </>
  )
}

export default Projects