import { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const Dictaphone = () => {
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const [page, setPage] = useState('')

    const change = () => {
        window.location.href = `/${transcript}`
    }

    useEffect(() => {
        
        setPage(transcript)

    }, [transcript]);

    useEffect(() => {
        console.log(1);
        switch (transcript.toLowerCase()) {
            case "project":

                window.location.href = `/#projects`

                break;
            case "home":
                window.location.href = `/#`

                break;
            case "technologies":
                window.location.href = `/#technologies`

                break;
            case "about":
                window.location.href = `/#about`
           
                break;
            case "resume":
                window.location ="https://drive.google.com/file/d/1C6ABmbK2_w9zxiSK_xsey6gQbzdiMzx9/view?usp=share_link"

                break;
            case  "get home" :
                window.location = "https://github.com/spyatmycode";
                break
            case  "github" :
                window.location = "https://github.com/spyatmycode";
                break
            case "get up" :
                window.location = "https://github.com/spyatmycode";
                break
            case ("contact" || "contacts"):
                 window.location.href = `/#contact`

                 break;
            case "linkedin":
                 window.location = `https://www.linkedin.com/in/oluwanifemi-akeju-40b027231/`

                 break;
            case "twitter":
                 window.location = `https://twitter.com/nifeminotifemi`

                 break;
                


            default:
                
                break;
        }

    }, [transcript]);



   const body = document.querySelector("body")

   body.addEventListener('mousemove', SpeechRecognition.startListening)



    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    return (
        <div>
           {/*  <p>Microphone: {listening ? 'on' : 'off'}</p>
            <button onClick={SpeechRecognition.startListening}>Start</button>
            <button onClick={SpeechRecognition.stopListening}>Stop</button>
            <button onClick={resetTranscript}>Reset</button>
            <p>{transcript}</p> */}
        </div>
    );
};
export default Dictaphone;