import React from 'react'
import { useForm} from '@formspree/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  Bounce, Slide } from "react-awesome-reveal";
import Dictaphone from './DIctaphone';

const Contact = () => {

   


    const notify = () => toast("Success ! Your email has been sent.")
    const [state, handleSubmit] = useForm("moqzyrno");

    if (state.succeeded) {
        notify()
        

    }


    return (
        <>

           


            <div className='lg:w-[500px] w-full pb-4' id='contact'>
                <h2 className='font-bold text-left text-xl uppercase opacity-80'>
                    Let's Talk
                </h2>

                <Bounce triggerOnce={true}>
                    <p className='font-semibold my-3 text-lg'>
                        Hope you're down to collab on a project because I certainly am. Shoot a DM !
                    </p>
                </Bounce>


                <Slide triggerOnce={true}>
                    <form className='flex flex-col bg-transparent gap-4'

                        onSubmit={handleSubmit}
                       

                    >

                        <input type="text" className='bg-transparent border-[#0c4481] border-4 outline-none p-3'

                            placeholder='Tell me your name'
                            name="fullname"
                            required

                        />



                        <input type="text" className='bg-transparent border-[#0c4481] border-4 outline-none p-3'
                            placeholder="What's your email ?"
                            name='email'
                            required

                        />

                        <textarea name="description" id="" cols="30" rows="10" draggable="false"

                            className='bg-transparent border-[#305d8e] border-4 outline-none p-3'
                            placeholder='Shoot your shot !'
                            required


                        ></textarea>

                        <input type="submit" value="SEND" className='bg-[#0c4481] border-none outline-none p-3 cursor-pointer rounded-md font-bold' />

                    </form>
                </Slide>

                <Dictaphone/>

            </div>
        </>
    )
}

export default Contact