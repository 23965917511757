import React, { useContext } from 'react'
import { NavLink,Link } from 'react-router-dom'
import { ImageContext } from '../providers/ImageProvider'

const Nav = () => {

    const {show, setShow} = useContext(ImageContext)
    return (
        <>
            <div className='flex justify-between items-center px-[10vw] py-5 gap-4 sticky top-0 bg-[#1a2232] w-full z-50'>
                <div className='font-bold hidden md:block text-2xl'>
                    <h1 onClick={()=>setShow(true)}>
                        Oluwanifemi
                    </h1>
                </div>
                <ul className='flex justify-between gap-5 font-semibold opacity-75 nav_links'>
                    <li><a href="#projects">Projects</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href="#contact">Contact</a></li>
                   
                    
                </ul>
            </div>
        </>
    )
}

export default Nav