import React from 'react'
import Projects from './Projects'
import About from './About'
import Contact from './Contact'

const Aside = () => {
  return (
    <>  
       <div className='!overflow-scroll flex flex-col items-center justify-center h-full md:w-auto lg:w-[500px] px-[10vw] pt-5'> 
        <Projects />
        <About />
        <Contact />
        

       </div>
    </>
  )
}

export default Aside