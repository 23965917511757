import React from 'react'
import Nav from './Nav'
import Aside from './Aside'
import {FaLinkedinIn, FaTwitter, FaGithub} from 'react-icons/fa'
import { Fade,Reveal,Bounce,Hinge ,Slide,JackInTheBox} from "react-awesome-reveal";

const Main = () => {
  return (


    <div className='flex flex-col '>
    <Nav />
       <div className='w-full flex flex-col pl-[10vw] h-full justify-center  pr-20 min-h-screen gap-5'>
        <span className='font-bold text-3xl flex gap-4'>Hey there <JackInTheBox triggerOnce={false}>👋🏾</JackInTheBox></span>

       <Slide triggerOnce={true} >
       <p className=' text-4xl lg:text-6xl font-bold'>I am <span className='text-blue-600'>Oluwanifemi.</span></p>
       </Slide>

        <Slide direction='right' triggerOnce={true}>
        <p className='font-medium text-base tracking-wider'>
        As a <span className='text-sky-500'>software engineer</span>, I possess a strong passion for optimizing functionality and efficiency to the highest level. With cutting-edge technology at my disposal, I develop applications that operate seamlessly and look aesthetically pleasing.
        </p>
        </Slide>

        <a href="https://drive.google.com/file/d/1lmTW5Nle3Cv_o7Yywk4qBUHKxezb0MM4/view?usp=sharing" target='_blank'

        className=' bg-white bg-opacity-5 text-center py-5 rounded-sm font-bold text-xl hover:bg-opacity-10 hover:transition-all'
        
        >Download Resume</a>


        <div className='links flex gap-5'>

            <a href="https://github.com/spyatmycode" className='social_links p-5'>
            <FaGithub size={35}/>
            <span>GitHub</span>
            </a>

            <a href="https://www.linkedin.com/in/oluwanifemi-akeju-40b027231/" className='social_links p-5'>
                <FaLinkedinIn size={35}/>
                <span>LinkedIn</span>
                
                
                
            </a>
            <a href="https://twitter.com/nifeminotifemi" className='social_links p-5'>
            <FaTwitter size={35} />

            <span>Twitter</span>
                
            </a>


        </div>
        
       </div>

      


    </div>
  
  )
}

export default Main