import React from 'react'
import Main from './Main'
import Aside from './Aside'

const Render = () => {
  return (
   <div className=' h-screen render'>
    <div className='lg:w-1/2 lg:fixed h-screen'>
    <Main />
    </div>
   <div className='lg:w-1/2 relative lg:left-1/2 flex justify-center'>
   <Aside />
   </div>
   </div>
  )
}

export default Render